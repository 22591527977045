/**
 * Global
 */

body {
  font-family: $font-family-base;
  scroll-behavior: smooth;

  p {
    line-height: 1.8;
  }
}

a {
  text-decoration: none;
  background-color: transparent;
  transition: opacity 100ms ease-in-out;

  &:hover {
    opacity: 0.85;
  }
}

main {
  a {
    color: color('schwarz');
  }
}

.wp-block-image .alignright {
  all: revert;
  margin: 0 0 1rem auto;

  figure {
    margin: 0;
  }

  img {
    margin: 0 0 1rem auto;
  }
}

.site-content {
  @media (min-width: breakpoint(xl)) {
    all: revert;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }
}

svg,
embed,
object {
  display: block;
  height: auto;
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// Anchor scroll with fixed header offset
:target::before {
  content: '';
  display: block;
  height: 10rem;
  margin: -10rem 0 0;
}

.icon-columns img {
  margin: 2rem auto 1rem auto;
}

/* Kontform  */

.contact-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 5rem auto 0 auto;
  max-width: 40rem;
  overflow: hidden;
  width: 100%;

  input::placeholder,
  textarea::placeholder {
    color: color('weiss');
    opacity: 0.6;
  }

  input:not(.submit) {
    height: 4rem;
    width: 3rem;
    margin-left: 0;
  }

  .free-text-input input:not(.submit),
  textarea {
    margin: 0.5rem 0 1rem 0 !important;
    width: 100%;
    height: 4rem;
    padding: 0.4rem;
    border: 1px solid map-get($colors, 'orange');
    font-size: 1.15rem;
    background-color: color('orange');
    color: color('weiss');
  }

  textarea {
    height: 13rem;
  }

  label {
    display: flex;
    margin-top: 2rem;
  }

  .label-radio-btn-group {
    margin-left: 0;
  }

  .free-text-input label,
  .label-radio-btn-group {
    font-size: 1.5rem;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .submit {
    max-width: 50rem;
    background-color: map-get($colors, 'orange');
    color: map-get($colors, 'weiss');
    margin: 3rem auto;
    border: none;
    font-size: 1.2rem;
    padding: 1rem 4rem;
    text-transform: uppercase;
  }

  .submit-container {
    margin: auto;
    padding: auto;
  }
}

.adress a {
  color: color('orange') !important;
}

.triangle {
  position: relative;
}

.triangle::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 0 35px 70px 0;
  border-color: transparent #f7f7f7 transparent transparent;
}

.triangle::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 70px 0 0 35px;
  border-color: transparent transparent transparent #faa73a;
}

.border {
  border-top: 1px solid color('graubraun');
}

.width-100 > div {
  max-width: 100% !important;
}

.items-stretch {
  @media (min-width: breakpoint(md)) {
    align-items: stretch;
  }
}

.max-width-box {
  max-width: 450px;
}

.no-wrap {
  flex-wrap: nowrap;
}

.stern-size {
  min-width: 40px;

  @media (min-width: breakpoint(sm)) {
    max-width: 68px;
  }
}

.flex-basis {
  flex-basis: 35% !important;

  @media (min-width: breakpoint(xs)) {
    flex-basis: 10% !important;
  }

  @media (min-width: breakpoint(md)) {
    flex-basis: 6% !important;
  }
}

.stern-before {
  position: relative;
}

.stern-before::before {
  background-image: url('../img/sd_stern_orange.svg');
  background-size: contain;
  content: '';
  width: 67px;
  height: 67px;
  position: absolute;
  top: -11px;
  left: -32px;
  z-index: 10;
}

.email::before,
.tel::before {
  background-size: contain;
  content: '';
  width: 38px;
  height: 38px;
  position: absolute;
  left: -50px;
  z-index: 10;
  background-repeat: no-repeat;
}

.email::before {
  background-image: url('../img/sd_icon_mail.svg');
  top: 1px;
}

.tel::before {
  background-image: url('../img/sd_icon_telefon.svg');
  top: -6px;
}

.email,
.tel {
  position: relative;
}

.beige-quadrat {
  margin: 0.5rem !important;
}

.resize-logo {
  img {
    height: 85px !important;
  }
}

.white-border {
  border: 10px solid #fff;
}

.hide {
  display: none;
}

.container,
.site-content > *,
.wp-block-group__inner-container {
  @media (min-width: 14600px) {
    max-width: initial !important;
  }

  @media (min-width: 1600px) {
    max-width: 1460px !important;
  }
}

.pr-38 {
  padding-right: 38px;
}

.border-orange {
  border: 8px solid color('orange');
  margin: 1rem 2rem 0 2rem;

  img {
    margin: 1rem -1rem -1rem 1rem;
  }
}

.border-dunkelorange {
  border: 8px solid color('dunkelorange');
  margin: 1rem 2rem 0 2rem;

  img {
    margin: 1rem -1rem -1rem 1rem;
  }
}

.border-schwarz {
  border: 8px solid color('primary');
  margin: 1rem 2rem 0 2rem;

  img {
    margin: 1rem -1rem -1rem 1rem;
  }
}

.border-weiss {
  border: 8px solid color('secondary');
  margin: 1rem 2rem 0 2rem;

  img {
    margin: 1rem -1rem -1rem 1rem;
  }
}

.border-grau {
  border: 8px solid color('grau');
  margin: 1rem 2rem 0 2rem;

  img {
    margin: 1rem -1rem -1rem 1rem;
  }
}
