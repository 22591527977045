/** Config */
@import 'config/variables';
@import 'common/global';

/**
* Styling the Login Screen
*/
.login h1 a,
#login h1 a {
  background-size: 9rem;
  height: 9rem;
  width: 9rem;
  background-position: center;
  margin: 2rem auto;
}

.login #backtoblog a,
.login #nav a {
  background-color: map-get($colors, 'secondary');
}

/**
* Styling the editor
*/
.wp-block {
  max-width: 50vw;

  &[data-align='full'] {
    max-width: none;
  }

  &[data-align='wide'] {
    max-width: calc(50vw + 200px);
  }
}
